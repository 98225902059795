import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
// Global file and config
import { setServerData, setData, STORAGE_KEYS } from '../../../../../new-moyo/src/js/native/helpers/storage.js';
import { request } from '../../../../../new-moyo/src/js/native/helpers/fetch.js';
import { wordDeclination } from '../../../../../new-moyo/src/js/native/helpers/declination.js';
import { EVENTS } from '../../../../../new-moyo/src/js/native/constants/events.js';
import Base64 from '../../../../../new-moyo/src/js/native/helpers/base64.js';
// Parts
import Modal from "../../components/Modal"
import ProductServicesItem from "./components/ProductServicesItem"

// max show services count
// if 0 - show all
const MAX_SERVICES = 0;
const DEFAULT_SHOW_COUNT = 2;

type ProductServicesType = {
  productId: string,
  async: string,
  mobile: boolean
}
type serviceItem = {
  description: string
  group_popup_icon: string
  title: string
  id: string
  products: {
    bonusValue: number
    calculationType: number
    code1c: string
    description: string
    oldPrice: null | {
      'data-price': number
    }
    price: number
    productId: number
    title: string
    type: string
    cautionText: string
  }[]
}
const ProductServices:FC<ProductServicesType> = (props) => {
  const { productId, async, mobile } = props;
  const { t } = useTranslation();
  const [isReady, setIsReady] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [services, setServices] = useState([]);
  const [servicesTexts, setServicesTexts] = useState<{
    servicesWords?: string,
    servicesBlockTitle?: string
    servicesBlockLess?: string
    servicesShowMore?: string
    servicesCancel?: string
    servicesAccept?: string
  }>({});
  const [activeServices, setActiveServices] = useState<number[]>([]);
  const [servicesModalImg, setServicesModalImg] = useState<string | null>(null);

  const getServices = useCallback(() => {
    const url = Base64.decodeOrReturnOriginal(async);
    request(url, 'GET', undefined, { productId }).then((res: { status: unknown; data: {
        groups?: []
        texts?: {}
      }}) => {
      const { status, data } = res;

      if (status && data) {
        const responseServices = data.groups || [];
        const servicesList = MAX_SERVICES ? responseServices.slice(0, MAX_SERVICES) : responseServices;
        const texts = data.texts || {};

        if (servicesList.length) {
          setServices(servicesList);
          setServicesTexts(texts);
          setServerData({ [productId]: data }, 'services');
          setIsReady(true);
          window.app.callEvent(EVENTS.SERVICES_DATA_PRODUCT_LOADED);
        }
      }
    });
  }, [async, productId]);

  const setActiveService = (serviceId: number, isChecked: boolean, serviceActiveId: string) => {
    let newActiveServices = [...activeServices, serviceId];

    if (!isChecked) {
      const serviceItems: undefined | { products: { productId: number; }[] } = services.find((service: { id: string, productId: string }) => service.id === serviceActiveId);

      if (serviceItems) {
        // @ts-ignore
        const serviceItemsProductsIds = serviceItems.products.map((productItem: { productId: number; }) => productItem.productId);
        newActiveServices = [...activeServices].filter(currentServiceId => (
          !serviceItemsProductsIds.some((itemId: number) => itemId === currentServiceId))
        );
      } else {
        return;
      }

    }

    setActiveServices(newActiveServices);
  };

  const isActiveService = useCallback((products = []) => (
    activeServices.some((serviceId) => (products.find((product: { productId: number; }) => (product.productId === serviceId))))
  ), [activeServices]);

  const servicesList = useMemo(() => {
    if (showAll) {
      return services;
    }
    if (activeServices.length) {
      const resultList = new Set([...services.slice(0, DEFAULT_SHOW_COUNT), ...services.filter((service: { products: [] }) => (isActiveService(service.products)))])
      return Array.from(resultList);
    }
    return services.slice(0, DEFAULT_SHOW_COUNT);
  }, [showAll, activeServices.length, services, isActiveService]);

  const servicesDifference = useMemo(() => services.length - servicesList.length, [services, servicesList]);

  const isVisibleShowMoreBtn = useMemo(() => {
    const servicesCount = services.length;

    if (activeServices.length === servicesCount) {
      return false;
    }

    return servicesCount > DEFAULT_SHOW_COUNT;
  }, [services, activeServices]);

  const servicesWordForm = useMemo(() => {
    const serviceForms = servicesTexts.servicesWords;

    if (serviceForms) {
      return wordDeclination(servicesDifference, serviceForms);
    }
    return t('product.services.services');

  }, [servicesTexts.servicesWords, t, servicesDifference]);

  useEffect(() => {
    getServices();
  }, [getServices]);

  useEffect(() => {
    setData(STORAGE_KEYS.CHECKED_SERVICES, activeServices);
  }, [activeServices]);

  if (!isReady) {
    return null;
  }

  const callSetServicesModalClose = () => setServicesModalImg(null);
  const callSetShowAll = () => setShowAll(!showAll);
  const callSetServicesModalOpen = (imgUrl: string) => setServicesModalImg(imgUrl);

  return (
    <>
      <Modal onClose={callSetServicesModalClose} open={Boolean(servicesModalImg)}>
        <img src={servicesModalImg || '#'} alt=""/>
      </Modal>
      <div className="product_info_section-title js-toggle-title">
        {servicesTexts.servicesBlockTitle}
      </div>
      <div className="product_features_content-holder js-toggle-content">
        <div className="services">
          {servicesList.map((service: serviceItem) => (
            <ProductServicesItem
              description={service.description}
              group_popup_icon={service.group_popup_icon}
              products={service.products}
              title={service.title}
              id={service.id}
              key={service.id}
              isActive={isActiveService(service.products)}
              setActiveService={setActiveService}
              showInfoModal={callSetServicesModalOpen}
              mobile={mobile}
              servicesTexts={servicesTexts}
            />
          ))}
        </div>
        {isVisibleShowMoreBtn && (
          <div
            className={`product_info_section-more ${showAll ? 'active' : null}`}
            onKeyDown={callSetShowAll}
            onClick={callSetShowAll}
          >
            {showAll ? servicesTexts.servicesBlockLess : `${servicesTexts.servicesShowMore} ${servicesDifference} ${servicesWordForm}`}
          </div>
        )}
      </div>
    </>
  );
};

const ProductServicesPortal: FC = () => {
  const Root = document.getElementById('react_container__portal--product_services');
  const productId = Root?.dataset?.productId || '';
  const dataAsync = Root?.dataset?.async || '';
  const mobile = Boolean(Root?.dataset?.mobile);
  return Root ? createPortal(<ProductServices productId={productId} async={dataAsync} mobile={mobile}/>, Root) : null;
};

export default ProductServicesPortal;
