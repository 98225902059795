// Core
import axios from 'axios';
import md5 from 'md5';
import { ajax } from "rxjs/ajax";
// TS
import {
  postOrderStatusAsyncTypes,
  postOrderStatusQuestionFormAsyncTypes,
  postOrderStatusLeadsCreateTypes,
  postCanceledUserOrderAsyncTypes
} from '../core/orderStatus/saga/asyncAction';
import { postRepairStatusAsyncTypes } from '../core/repairStatus/saga/asyncAction';
import { postBrandsAsyncTypes, postModelsAsyncTypes, postQuestionsAsyncTypes } from '../core/tradeIn/saga/asyncAction';
// Helpers
import helpers from '../../ui/components/_Form/helpers'
// Config
export const headersType = {
  default: {
    'Content-Type': 'application/json'
  },
  json: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
  },
  XMLHttpRequest: {
    'X-Requested-With': 'XMLHttpRequest'
  },
  urlEncoded: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'X-Requested-With': 'XMLHttpRequest',
  },
  antiSpam: {
    'Content-Form-Data': md5(process.env.REACT_APP_REQUEST_VALUE_FOR_HASH_FOR_ANTISPAM || ''),
  },
  multipartFormData: {
    'Content-Type': 'multipart/form-data',
  }
}

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_DOMAIN}`,
  withCredentials: true,
});

export const axiosAPI = Object.freeze({
  assets: {
    getValidationTexts() {
      return instance.get(`${process.env.REACT_APP_API_ENDPOINT}/validation/texts`, {
        headers: headersType.json,
      });
    },
  },
  sendMailOrderStatus(params: postOrderStatusQuestionFormAsyncTypes) {
    return instance.post(`sendMailOrderStatus`, helpers.getFormData(params), {
      headers: headersType.XMLHttpRequest
    });
  },
  orderStatusLeads: {
    _controller: `/order-status-leads`,
    create(params: postOrderStatusLeadsCreateTypes) {
      return instance.post(`${axiosAPI.orderStatusLeads._controller}/create`, helpers.getFormData(params), {
        headers: headersType.XMLHttpRequest
      })
    }
  },
  user: {
    _controller: `${process.env.REACT_APP_API_ENDPOINT}/user`,
    getUserData() {
      return instance.get(axiosAPI.user._controller, { headers: headersType.json });
    },
    getUserAuthTexts() {
      return instance.get(`${axiosAPI.user._controller}/authTexts`, { headers: headersType.json });
    },
    regUser(params: object) {
      return instance.post(`${axiosAPI.user._controller}/loginPhoneOrRegistration`,
        params,
        { headers: headersType.antiSpam });
    },
    loginUserByPhone(params: { isRecaptcha: unknown, verifyCode: unknown }) {
      const { isRecaptcha, ...resParams } = params;
      if (!isRecaptcha) {
        delete resParams.verifyCode
      }
      return instance.post(`${axiosAPI.user._controller}/loginPhone`, resParams, {
        headers: headersType.antiSpam,
      });
    },
    loginUserByEmail(params: { isRecaptcha: unknown, verifyCode: unknown }) {
      const { isRecaptcha, ...resParams } = params;
      if (!isRecaptcha) {
        delete resParams.verifyCode
      }
      return instance.post(`${axiosAPI.user._controller}/loginEmail`, resParams, {
        headers: headersType.json,
      });
    },
    resendUserSms(params: object) {
      return instance.post(`${axiosAPI.user._controller}/resendSMS`, params, {
        headers: headersType.antiSpam
      });
    },
    verifyUserPhone(params: object) {
      return instance.post(`${axiosAPI.user._controller}/phoneVerify`, params, {
        headers: headersType.json,
      });
    },
    resendUserEmail(params: object) {
      return instance.post(`${axiosAPI.user._controller}/resendEmail`, params, {
        headers: headersType.json,
      });
    },
    getUsrpowName(code: unknown) {
      return instance.get(`${axiosAPI.user._controller}/usrpow`, {
        params: { code },
        headers: headersType.json,
      });
    },
  },
  users: {
    _controller: `/users`,
    cancelledUserOrder(params: postCanceledUserOrderAsyncTypes) {
      return instance.post(
        `${axiosAPI.users._controller}/cancelledUserOrder`,
        helpers.getFormData(params),
        {
          headers: headersType.XMLHttpRequest
        })
    }
  },
  basket: {
    _controller: `/basket`,
    postStatus(params: postOrderStatusAsyncTypes) {
      return instance.post(`${axiosAPI.basket._controller}/status`, helpers.getFormData(params), {
        headers: headersType.urlEncoded
      });
    },
    postWarrantyReceiptStatus(params: postRepairStatusAsyncTypes) {
      return instance.post(`${axiosAPI.basket._controller}/WarrantyReceiptStatus`,
        helpers.getFormData(params),
        {
          headers: headersType.urlEncoded
        });
    },
  },
  tradeIn: {
    _controller: `${process.env.REACT_APP_API_ENDPOINT}/tradeIn`,
    postTypes() {
      return instance.post(`${axiosAPI.tradeIn._controller}/types`, {  }, {
        headers: headersType.default
      });
    },
    postBrands(params: postBrandsAsyncTypes) {
      return instance.post(`${axiosAPI.tradeIn._controller}/brands`, params, {
        headers: headersType.default,
      });
    },
    postModels(params: postModelsAsyncTypes) {
      return instance.post(`${axiosAPI.tradeIn._controller}/items`, params, {
        headers: headersType.default,
      });
    },
    postQuestions(params: postQuestionsAsyncTypes) {
      return instance.post(`${axiosAPI.tradeIn._controller}/questions`, params, {
        headers: headersType.default,
      });
    },
    postGrade(params: object) {
      return instance.post(`${axiosAPI.tradeIn._controller}/grade`, params, {
        headers: headersType.default,
      });
    },
    laptop: {
      _controller: `${process.env.REACT_APP_API_ENDPOINT}/tradeInLaptop`,
      postItems(params: object) {
        return instance.post(`${axiosAPI.tradeIn.laptop._controller}/items`, params,{
          headers: headersType.default,
        });
      },
      postQuestions(params: object) {
        return instance.post(`${axiosAPI.tradeIn.laptop._controller}/questions`, params, {
          headers: headersType.default,
        });
      },
      postGrade(params: object) {
        return instance.post(`${axiosAPI.tradeIn.laptop._controller}/grade`, params, {
          headers: headersType.default,
        });
      },
    }
  },
  cities: {
    _controller: `${process.env.REACT_APP_API_ENDPOINT}/Cities`,
    getCities(params = {}) {
      return instance.get(axiosAPI.cities._controller, {
        headers: headersType.json,
        params,
      });
    },
    getAutocomplete(searchString: string, isInternational = false) {
      if (isInternational) {
        return instance.post(`/site/cityAutoComplete?term=${searchString}`, {}, {
          headers: headersType.json,
        });
      }
      return instance.get(`${axiosAPI.cities._controller}/autocomplete`, {
        headers: headersType.json,
        params: {
          value: searchString
        }
      });
    }
  },
  delivery: {
    _controller: `${process.env.REACT_APP_API_ENDPOINT}/DeliveryMethods`,
    getDeliveryMethods(params: object) {
      return instance.get(axiosAPI.delivery._controller, {
        params,
        headers: headersType.json
      });
    },
    getDeliveryMethodByAlias(params: object) {
      return instance.get(axiosAPI.delivery._controller, {
        params,
        headers: headersType.json
      });
    },
    getDeliveryIntervals(params: object) {
      return instance.get(`${axiosAPI.delivery._controller}/GetIntervals`, {
        params,
        headers: headersType.json
      });
    },
    getNPParcelsBySearch(input: string, cityId: unknown) {
      return instance.get(`${axiosAPI.delivery._controller}/NpParcelMachineSearchInput`, {
        params: { input, cityId },
        headers: headersType.json
      });
    },
    getNPParcelsByOffset(offset: number, cityId: unknown) {
      return instance.get(`${axiosAPI.delivery._controller}/NpParcelMachineBranchNumber`, {
        params: { offset, cityId },
        headers: headersType.json
      });
    },
  },
  graphApi: {
    _controller: `${process.env.REACT_APP_API_ENDPOINT}/graph`,
    graph(params: object) {
      return instance.post(axiosAPI.graphApi._controller, params, {
        headers: headersType.json,
      });
    },
    graphObservable(dataJson: object) {
      return ajax.post(axiosAPI.graphApi._controller, {
        headers: headersType.json,
        ...dataJson,
      })
    },
  },
  bonuses: {
    _controller: `${process.env.REACT_APP_API_ENDPOINT}/bonuses`,
    getBonusesData(params: { products: unknown }) {
      const { products } = params;
      return instance.post(`${axiosAPI.bonuses._controller}/forProducts`, products, {
        headers: headersType.json
      })
    },
    userVerification(phone: string) {
      const data = { phone: phone.replace("+", "") };
      return instance.post(`${axiosAPI.bonuses._controller}/UserVerification`, data,
        { headers: headersType.antiSpam }
      );
    },
    phoneVerify(params: object) {
      return instance.post(`${axiosAPI.bonuses._controller}/PhoneVerify`, params,
        { headers: headersType.antiSpam }
      );
    },
    resendSMS(params: object) {
      return instance.post(`${axiosAPI.bonuses._controller}/ResendSMS`, params,
        { headers: headersType.antiSpam }
      );
    },
    getBonusesValue(params: object) {
      return instance.post(`${axiosAPI.bonuses._controller}/byToken`, params,
        { headers: headersType.antiSpam }
      );
    }
  },
  street: {
    _controller: `${process.env.REACT_APP_API_ENDPOINT}/Street`,
    getStreetAutocomplete(params: object) {
      return instance.get(`${axiosAPI.street._controller}/autocomplete`, {
        headers: headersType.json,
        params
      });
    }
  },
  payment: {
    _controller: `${process.env.REACT_APP_API_ENDPOINT}/paymentMethods`,
    getPaymentMethods(params: object) {
      return instance.get(`${axiosAPI.payment._controller}`, {
        params
      });
    },
    getPaymentMethodByAlias(params: object) {
      return instance.get(`${axiosAPI.payment._controller}/data`, {
        params,
      });
    },
    getCreditFormByTypeCode(params: object) {
      return instance.get(`${axiosAPI.payment._controller}/creditForm`, {
        params,
      });
    },
  },
  product: {
    _controller: `${process.env.REACT_APP_API_ENDPOINT}/Product`,
    getServices(params: object) {
      return instance.get(`${axiosAPI.product._controller}/getServices`, {
        params,
      });
    },
  },
  products: {
    _controller: `${process.env.REACT_APP_API_ENDPOINT}/products`,
    getMarkdownData: ({ products }: { products: object }) => (
      instance.post(`${axiosAPI.products._controller}/getProductsMarkdown`, {
        products,
      }, { headers: headersType.json })
    ),
    getProductsAdditionalInfo: (products = [], settings = {}) => (
      instance.post(`${axiosAPI.products._controller}/getProductsInfo`, {
        products,
        settings,
      }, { headers: headersType.json })
    )
  },
  promoCode: {
    _controller: `${process.env.REACT_APP_API_ENDPOINT}/promocode`,
    getPromocodes() {
      return instance.get(axiosAPI.promoCode._controller);
    },
    applyPromocode(params: object) {
      return instance.post(`${axiosAPI.promoCode._controller}/apply`, params,
        { headers: headersType.json }
      );
    },
    applyAllPromocode(params: object) {
      return instance.post(`${axiosAPI.users._controller}/setAutoPromocode`, params,
          { headers: headersType.json }
      );
    },
    removePromocode(data: object) {
      return instance.delete(`${axiosAPI.promoCode._controller}/remove`, {
        headers: headersType.json,
        data,
      });
    },
    removeAllPromocodes() {
      return instance.delete(`${axiosAPI.promoCode._controller}/removeAll`, {
        headers: headersType.json
      });
    },
    checkCityCompatibility(params: object) {
      return instance.post(`${axiosAPI.promoCode._controller}/getCityIncompatiblePromocodes`,
        params,
        { headers: headersType.json }
      );
    },
    getInvalidPromocodes() {
      return instance.get(`${axiosAPI.promoCode._controller}/getInvalidPromocodes`);
    },
  },
  portal: {
    _controller: `${process.env.REACT_APP_API_ENDPOINT}/brandPortals`,
    getPortal(params: object) {
      return instance.get(`${axiosAPI.portal._controller}/index`, {
        params,
      });
    },
    getPortalProducts(params: object) {
      return instance.get(`${axiosAPI.portal._controller}/getproductsblock`, {
        params,
      })
    },
    getPortalMoreProducts(params: object) {
      return instance.get(`${axiosAPI.portal._controller}/getMoreProducts`, {
        params,
      });
    }
  },
  cart: {
    _controller: `${process.env.REACT_APP_API_ENDPOINT}/Cart`,
    getCart(params: object) {
      return instance.get(axiosAPI.cart._controller, {
        params,
      });
    },
    removeCartItem(data: object) {
      return instance.delete(`${axiosAPI.cart._controller}/ProductRemove`, {
        data,
        headers: headersType.json,
      });
    },
    updateCartItemCount(params: object) {
      return instance.put(`${axiosAPI.cart._controller}/ProductUpdateCount`, params, {
        headers: headersType.json,
      });
    },
    setPriceInternal(data: object = {}) {
      return instance.post(`${axiosAPI.cart._controller}/SetPriceInternal`, data, {
        headers: headersType.json,
      });
    },
    addProducts(params: object) {
      return instance.post(`${axiosAPI.cart._controller}/addProducts`, params, {
        headers: headersType.json,
      });
    },
    getCartCredit(params: object) {
      return instance.get(`${axiosAPI.cart._controller}/getCartCreditsData?nocache=${new Date().getTime()}`, {
        headers: headersType.json,
        params,
      });
    },
    getProductsDates(params: object) {
      return instance.get(`${axiosAPI.cart._controller}/getDates`, {
        headers: headersType.json,
        params,
      });
    },
  },
  checkout: {
    _controller: `${process.env.REACT_APP_API_ENDPOINT}/checkout`,
    getDiiaQr() {
      return instance.get(`${axiosAPI.checkout._controller}/getDiiaQr`)
    },
    getUserInfo(params: object) {
      return instance.get(`${axiosAPI.checkout._controller}/getUserInfo`, {
        params,
        headers: headersType.antiSpam
      });
    },
    getAdditionals() {
      return instance.get(`${axiosAPI.checkout._controller}/additional`);
    },
    getCheckoutInitials() {
      return instance.get(`${axiosAPI.checkout._controller}/init`, {
        headers: headersType.json,
      });
    },
    getCheckoutContactsStep() {
      return instance.get(`${axiosAPI.checkout._controller}/contactsStep`, {
        headers: headersType.json,
      });
    },
    getUserTerms() {
      return instance.get(`${axiosAPI.checkout._controller}/terms`, {
        headers: headersType.json,
      });
    },
    getSellerShops(params: object) {
      return instance.get(`${axiosAPI.checkout._controller}/getSellerShops`, {
        params,
        headers: headersType.json,
      });
    },
    uploadDocument(params: { type: string, file: Blob }) {
      const { type, file } = params;
      const formData = new FormData();
      formData.append("type", type);
      formData.append("file", file);
      return instance.post(`${axiosAPI.checkout._controller}/uploadDocument`, formData, {
        headers: headersType.multipartFormData,
      });
    },
    removeDocument(params: object) {
      return instance.post(`${axiosAPI.checkout._controller}/removeDocument`, params, {
        headers: headersType.json,
      });
    },
    createOrder(params: object) {
      return instance.post(`${axiosAPI.checkout._controller}/CreateOrder`, params, {
        headers: headersType.json,
      });
    },
  }
});
