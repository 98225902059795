import * as types from '../actions/actionTypes';
import { AIR_DELIVERY } from '../actions/actionTypes';
import { DELIVERY_METHOD } from '../../../../engine/constants/index';
import { getMethodIdSelector } from '../helpers/functions'

const deliveryReducer = (state = initialState, action) => {
    switch (action.type) {
    case types.SET_DELIVERY_METHODS_DATA: {
        const payloadData = action.payload || {};
        const alias = payloadData.alias;
        const aliasData = payloadData.data;

        return {
            ...state,
            [alias]: aliasData,
        }
    }
    case types.GET_DELIVERY_METHODS_SUCCESS: {
        const data = action.payload;
        let hasAvailableIntervalsDefault = false;
        let intervalDateByDefault = null;

        if (data && data.methods && data.methods.length) {
            let activeMethodAlias = null;
            let activeMethodData = null;
            let activeMethodDefaultData = null;
            let defaultActiveStore = {};
            let methodIdSelector = null;
            let isActiveUpToFloor = false;
            let upToFloorMessage = null;
            let hasOversizedProduct = false;
            let floorLimit = DELIVERY_METHOD.FLOOR_LIMIT;

            data.methods.forEach(method => {
                if (method.active === true) {
                    activeMethodAlias = method.alias;
                    activeMethodData = method.data;
                    methodIdSelector = getMethodIdSelector(activeMethodAlias);
                    activeMethodDefaultData = method.defaultData && method.defaultData.constructor.name === 'Object' && method.defaultData;
                    isActiveUpToFloor = method.isActiveUpToFloor;
                    if (method.upToFloorMessage) {
                        upToFloorMessage = method.upToFloorMessage;
                    }
                    if (method.hasOversizedProduct) {
                        hasOversizedProduct = method.hasOversizedProduct;
                    }
                    if (method.floorLimit) {
                        floorLimit = method.floorLimit;
                    }
                }
            });

            if ((activeMethodAlias === 'courier' || activeMethodAlias === 'toShop') && activeMethodData.intervals) {
                hasAvailableIntervalsDefault = !!Object.keys(activeMethodData.intervals).length;
                intervalDateByDefault = Object.keys(activeMethodData.intervals)[0];
            }

            if (activeMethodData) {
                const defaultShopsOrBranches = activeMethodData.shops ? activeMethodData.shops : activeMethodData.branches;

                defaultShopsOrBranches &&
                !(state && state[activeMethodAlias]?.choosedStore && defaultShopsOrBranches.some(store => {
                        // збереження активного магазину якщо він доступний
                        if (+store.id === state[activeMethodAlias].choosedStore.id) {
                            defaultActiveStore = state[activeMethodAlias].choosedStore;
                            return true;
                        }
                    })

                ) && !(activeMethodDefaultData && defaultShopsOrBranches.some(store => {
                    // Виведення збереженої адреси якщо є в списку доступних для вибору
                    if (+store.id === activeMethodDefaultData[methodIdSelector]) {
                        defaultActiveStore = store;
                        return true;
                    }
                })) &&
                defaultShopsOrBranches.some(store => {
                    // Виведення активної по дефолту адреси
                    if (store.active) {
                        defaultActiveStore = store;
                        return true;
                    }
                });
            }

            let defaultFormValues = state && state[activeMethodAlias]
                ? { ...state[activeMethodAlias].formValues }
                : {};

            let courierCookieFormValues = {};
            if (activeMethodAlias === 'courier' || activeMethodAlias === DELIVERY_METHOD.AIR_ADDRESS) {
                if (data.cookieSavedData) {
                    const {
                        street,
                        house,
                        housing,
                        apartment,
                        upToFloor,
                        floor,
                        elevatorType,
                    } = data.cookieSavedData;
                    courierCookieFormValues = Object.assign({}, {
                        street: street ? street : null,
                        house: house ? house : null,
                        housing: housing ? housing : null,
                        apartment: apartment ? apartment : null,
                        upToFloor: upToFloor ? upToFloor : null,
                        floor: floor ? floor : '',
                        elevatorType: elevatorType ? elevatorType : null,
                    });

                    if (hasOversizedProduct && elevatorType === '0') {
                        courierCookieFormValues = {
                            ...courierCookieFormValues,
                            upToFloor: false,
                            floor: '',
                            elevatorType: null,
                        }
                    }

                    defaultFormValues = Object.assign({}, defaultFormValues, courierCookieFormValues);
                }

                if (activeMethodDefaultData) {
                    const {
                        street_name: street,
                        street_house: house,
                        street_apartment: apartment,
                    } = activeMethodDefaultData;

                    const userCourierData = {
                        ...street && { street },
                        ...house && { house },
                        housing: null,
                        ...apartment && { apartment },
                    }

                    defaultFormValues = {
                        ...defaultFormValues,
                        ...userCourierData,
                    }
                }

            }

            return {
                ...state,
                ...data,
                toShop: {},
                novaPoshta: {},
                justIn: {},
                courier: {},
                airBranch: {},
                airAddress: {},
                activeMethodAlias,
                [activeMethodAlias]: {
                    ...activeMethodData,
                    formValues: defaultFormValues,
                    choosedStore: defaultActiveStore,
                    hasAvailableIntervals: hasAvailableIntervalsDefault,
                    intervalDate: intervalDateByDefault,
                    intervalId: null,
                    isActiveUpToFloor: isActiveUpToFloor,
                    upToFloorMessage: upToFloorMessage,
                    hasOversizedProduct: hasOversizedProduct,
                    floorLimit: floorLimit,
                },
                isDMSIntervalsFirstLoadByCookiesCompleted: false,
            }
        } else if (data && data.methods && data.methods.length === 0) {
            return {
                ...state,
                ...data,
            }
        } else {
            return state;
        }
    }
    case types.GET_DELIVERY_METHOD_BY_ALIAS_SUCCESS: {
        let hasAvailableIntervalsDefault = false;
        let intervalDateByDefault = null;
        let activeMethodDefaultData = null;
        const activeMethodAlias = action.payload.alias;
        const methodIdSelector = getMethodIdSelector(activeMethodAlias);
        let upToFloorMessage = null;
        let hasOversizedProduct = false;
        let isActiveUpToFloor = false;
        let floorLimit = DELIVERY_METHOD.FLOOR_LIMIT;

        state.methods.some(method => {
            if (method.alias === activeMethodAlias) {
                activeMethodDefaultData = method.defaultData && method.defaultData.constructor.name === 'Object' && method.defaultData;
                isActiveUpToFloor = method.isActiveUpToFloor;
                if (method.upToFloorMessage) {
                    upToFloorMessage = method.upToFloorMessage;
                }
                if (method.hasOversizedProduct) {
                    hasOversizedProduct = method.hasOversizedProduct;
                }
                if (method.floorLimit) {
                    floorLimit = method.floorLimit;
                }
                return true;
            }
        });

        if (action.payload && action.payload.data.methods && action.payload.alias === 'courier') {
            let method = action.payload.data.methods.find(el => el.alias === 'courier');
            upToFloorMessage = method.upToFloorMessage;
            hasOversizedProduct = method.hasOversizedProduct;
            floorLimit = method.floorLimit;
        }

        if (action.payload && action.payload.data.intervals && (action.payload.alias === 'courier' || action.payload.alias === DELIVERY_METHOD.TO_SHOP_ALIAS)) {
            hasAvailableIntervalsDefault = !!Object.keys(action.payload.data.intervals).length;
            intervalDateByDefault = Object.keys(action.payload.data.intervals)[0];
        }

        const { data } = action.payload;
        let defaultActiveStore = {};
        const defaultShopsOrBranches = data.shops ? data.shops : data.branches;

        defaultShopsOrBranches &&
        !(state[activeMethodAlias]?.choosedStore && defaultShopsOrBranches.some(store => {
                // збереження активного магазину якщо він доступний
                if (+store.id === state[activeMethodAlias].choosedStore.id) {
                    defaultActiveStore = state[activeMethodAlias].choosedStore;
                    return true;
                }
            })

        ) && !(activeMethodDefaultData && defaultShopsOrBranches.some(store => {
            // Виведення збереженої адреси якщо є в списку доступних для вибору
            if (+store.id === activeMethodDefaultData[methodIdSelector]) {
                defaultActiveStore = store;
                return true;
            }
        }))
        &&
        defaultShopsOrBranches.forEach(store => {
            if (store.active) {
                defaultActiveStore = store;
            }
        });

        let courierCookieFormValues = {};
        if (activeMethodAlias === 'courier' || activeMethodAlias === DELIVERY_METHOD.AIR_ADDRESS) {
            if (state.cookieSavedData) {
                const {
                    street,
                    house,
                    housing,
                    apartment,
                    upToFloor,
                    floor,
                    elevatorType,
                } = state.cookieSavedData;
                courierCookieFormValues = Object.assign({}, {
                    street: street ? street : null,
                    house: house ? house : null,
                    housing: housing ? housing : null,
                    apartment: apartment ? apartment : null,
                    upToFloor: upToFloor ? upToFloor : null,
                    floor: floor ? floor : '',
                    elevatorType: elevatorType ? elevatorType : null,
                });

                if (hasOversizedProduct && elevatorType === '0') {
                    courierCookieFormValues = {
                        ...courierCookieFormValues,
                        upToFloor: false,
                        floor: '',
                        elevatorType: null,
                    }
                }
            }

            if (activeMethodDefaultData) {
                const {
                    street_name: street,
                    street_house: house,
                    street_apartment: apartment,
                } = activeMethodDefaultData;

                const userCourierData = {
                    ...street && { street },
                    ...house && { house },
                    housing: null,
                    ...apartment && { apartment },
                }

                courierCookieFormValues = {
                    ...courierCookieFormValues,
                    ...userCourierData,
                }
            }
        }

        // a crutch is written because an object has mutated somewhere
        let newMethods = [
            ...data.methods || state.methods,
        ];

        const prevFormValues = state[activeMethodAlias] && state[activeMethodAlias].formValues || {};

        return {
            ...state,
            [activeMethodAlias]: {
                ...state[activeMethodAlias],
                ...action.payload.data,
                hasAvailableIntervals: hasAvailableIntervalsDefault,
                choosedStore: defaultActiveStore,
                intervalDate: intervalDateByDefault,
                intervalId: null,
                isActiveUpToFloor: isActiveUpToFloor,
                upToFloorMessage: upToFloorMessage,
                hasOversizedProduct: hasOversizedProduct,
                floorLimit: floorLimit,
                formValues: {
                    ...courierCookieFormValues,
                    ...prevFormValues,
                },
            },
            methods: [...newMethods],
        }
    }
    case types.SET_DELIVERY_METHOD_ACTIVE:
        return {
            ...state,
            activeMethodAlias: action.payload,
        }
    case types.SET_DELIVERY_CHOOSED_STORE_BY_ALIAS:
        return {
            ...state,
            [action.payload.alias]: {
                ...state[action.payload.alias],
                choosedStore: action.payload.data,
            },
        }
    case types.SET_DELIVERY_CHOOSED_COURIER_FORM:
        return {
            ...state,
            courier: {
                ...state.courier,
                formValues: Object.assign({}, state.courier.formValues, action.payload),
            },
        }
    case types.SET_DELIVERY_UPTOFLOORWIGHTLIMIT:
        return {
            ...state,
            upToFloorWightLimit: action.payload,
        }

    case AIR_DELIVERY.SET_DELIVERY_CHOOSED_AIR_ADDRESS_COURIER_FORM:
        return {
            ...state,
            airAddress: {
                ...state.airAddress,
                formValues: {
                    ...state.airAddress.formValues,
                    ...action.payload,
                },
            },
        }

    case types.GET_DMS_INTERVALS_SUCCESS: {
        const activeMethodAlias = action.payload.alias;

        let hasAvailableIntervalsDMS = false;
        let intervalDateByDefault = null;
        let intervals = action.payload.data;
        let intervalValueByDefault = null;

        if (intervals && intervals.length) {
            hasAvailableIntervalsDMS = !!intervals.length;
            intervalDateByDefault = intervals[0].date;
            intervalValueByDefault = intervals[0].intervals[0].id;
        }

        return {
            ...state,
            [activeMethodAlias]: {
                ...state[activeMethodAlias],
                intervals,
                hasAvailableIntervals: hasAvailableIntervalsDMS,
                intervalDate: intervalDateByDefault,
                intervalId: intervalValueByDefault,
                intervalsErrors: false,
            },
        }
    }
    case types.SET_DELIVERY_INTERVALS_DATE: {
        const activeMethodAlias = state.activeMethodAlias;
        return {
            ...state,
            [activeMethodAlias]: {
                ...state[activeMethodAlias],
                intervalDate: action.payload,
            },
        }
    }
    case types.SET_DELIVERY_INTERVALS_INTERVAL: {
        const activeMethodAlias = state.activeMethodAlias;
        return {
            ...state,
            [activeMethodAlias]: {
                ...state[activeMethodAlias],
                intervalId: action.payload,
            },
        }
    }
    case types.SET_DELIVERY_INTERVALS_FIRST_LOAD_BY_COOKIES:
        return {
            ...state,
            isDMSIntervalsFirstLoadByCookiesCompleted: action.payload,
        }
    case types.GET_DMS_INTERVALS_ERROR: {
        const activeMethodAlias = state.activeMethodAlias;
        return {
            ...state,
            [activeMethodAlias]: {
                ...state[activeMethodAlias],
                intervals: null,
                hasAvailableIntervals: null,
                intervalDate: null,
                intervalId: null,
                intervalsErrors: true,
            },
        }
    }
    case types.SET_WAIT_INTERVALS:
        return {
            ...state,
            isWaitIntervals: action.payload,
        }
    default:
        return state;
    }
};

const initialState = null;

export default deliveryReducer;
