import * as actionTypes from "../actions/actionTypes";

const initialState = {
    contacts: {
        completed: false,
    },
    delivery: {
        completed: false,
    },
    payment: {
        completed: false,
    },
};

const progressReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_STEP_COMPLETENESS_STATE:
            return {
                ...state,
                ...{
                    [action.payload.stepName]: {
                        completed: action.payload.completed,
                        active: state[action.payload.stepName].active,
                    }
                }
            };
        default:
            return state;
    }
}

export default progressReducer;
