export const GET_CHECKOUT_INITIALS_SUCCESS = 'GET_CHECKOUT_INITIALS_SUCCESS';
export const GET_CHECKOUT_CONTACTS_SUCCESS = 'GET_CHECKOUT_CONTACTS_SUCCESS';

export const RESET_STEP_TO_ORDER = 'RESET_STEP_TO_ORDER';
export const SET_STEP_TO_ORDER = 'SET_STEP_TO_ORDER';
export const CREATE_ORDER_SET_ERRORS = 'CREATE_ORDER_SET_ERRORS';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const SET_ORDER_PROGRESS = 'SET_ORDER_PROGRESS';

export const GET_VALIDATION_TEXTS_SUCCESS = 'GET_VALIDATION_TEXTS';

export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const REMOVE_DOCUMENT_SUCCESS = 'REMOVE_DOCUMENT_SUCCESS';
export const UPDATE_COMPANIES_DATA = 'UPDATE_COMPANIES_DATA';
export const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE';

export const SET_STEP_COMPLETENESS_STATE = 'SET_STEP_COMPLETENESS_STATE';

export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const SET_CART_TYPE = 'SET_CART_TYPE';
export const SET_WAIT_CART = 'SET_WAIT_CART';
export const SET_WAIT_INTERVALS = 'SET_WAIT_INTERVALS';
export const GET_CART_PRODUCTS_DATES_SUCCESS = 'GET_CART_PRODUCTS_DATES_SUCCESS';

export const GET_CART_CREDIT_SUCCESS = 'GET_CART_CREDIT_SUCCESS';
export const SET_CREDIT_SUB_TYPE = 'SET_CREDIT_SUB_TYPE';
export const TOGGLE_CREDIT_TYPE = 'TOGGLE_CREDIT_TYPE';
export const SET_CREDIT_TYPE_DATA = 'SET_CREDIT_TYPE_DATA';
export const SET_CART_CREDIT_ERROR = 'SET_CART_CREDIT_ERROR';
export const CLEAR_CART_CREDIT = 'CLEAR_CART_CREDIT';
export const SET_CREDIT_TARGET_DATA = 'SET_CREDIT_TARGET_DATA';
export const TOGGLE_CREDIT_TARGET_TYPE = 'TOGGLE_CREDIT_TARGET_TYPE';

export const SET_RECOVERY_ITEMS = 'SET_RECOVERY_ITEMS';
export const CLEAR_RECOVERY_ITEMS = 'CLEAR_RECOVERY_ITEMS';

export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_AUTOCOMPLETE_SUCCESS = 'GET_CITIES_AUTOCOMPLETE_SUCCESS';

export const GET_DELIVERY_METHODS_SUCCESS = 'GET_DELIVERY_METHODS_SUCCESS';
export const GET_DELIVERY_METHOD_BY_ALIAS_SUCCESS = 'GET_DELIVERY_METHOD_BY_ALIAS_SUCCESS';
export const SET_DELIVERY_METHOD_ACTIVE = 'SET_DELIVERY_METHOD_ACTIVE';
export const SET_DELIVERY_CHOOSED_STORE_BY_ALIAS = 'SET_DELIVERY_CHOOSED_STORE_BY_ALIAS';
export const SET_DELIVERY_CHOOSED_COURIER_FORM = 'SET_DELIVERY_CHOOSED_COURIER_FORM';
export const SET_DELIVERY_MERCHANTS_FORM = 'SET_DELIVERY_MERCHANTS_FORM';
export const SET_DELIVERY_METHODS_DATA = 'SET_DELIVERY_METHODS_DATA';
export const SET_DELIVERY_UPTOFLOORWIGHTLIMIT = 'SET_DELIVERY_UPTOFLOORWIGHTLIMIT';

export const GET_DMS_INTERVALS_SUCCESS = 'GET_DMS_INTERVALS_SUCCESS';
export const GET_DMS_INTERVALS_ERROR = 'GET_DMS_INTERVALS_ERROR';
export const GET_DMS_APPROVED_INTERVAL_SUCCESS = 'GET_DMS_APPROVED_INTERVAL_SUCCESS';
export const GET_DMS_APPROVED_INTERVAL_FAIL = 'GET_DMS_APPROVED_INTERVAL_FAIL';
export const SET_DELIVERY_INTERVALS_DATE = 'SET_DELIVERY_INTERVALS_DATE';
export const SET_DELIVERY_INTERVALS_INTERVAL = 'SET_DELIVERY_INTERVALS_INTERVAL';
export const SET_DELIVERY_INTERVALS_FIRST_LOAD_BY_COOKIES = 'SET_DELIVERY_INTERVALS_FIRST_LOAD_BY_COOKIES';

export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHOD_BY_ALIAS_SUCCESS = 'GET_PAYMENT_METHOD_BY_ALIAS_SUCCESS';
export const GET_PAYMENT_ADDITIONALS_SUCCESS = 'GET_PAYMENT_ADDITIONALS_SUCCESS';
export const TOGGLE_PAYMENT_METHOD = 'TOGGLE_PAYMENT_METHOD';
export const SET_PAYMENT_ADDITIONAL = 'SET_PAYMENT_ADDITIONAL';
export const GET_CREDIT_FORM_BY_TYPE_CODE_SUCCESS = 'GET_CREDIT_FORM_BY_TYPE_CODE_SUCCESS';
export const SET_INITIAL_PAYMENT_METHOD_ALIAS = 'SET_INITIAL_PAYMENT_METHOD_ALIAS';
export const FOCUS_PAYMENT_FIELD = 'FOCUS_PAYMENT_FIELD';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_AUTH_TEXTS_SUCCESS = 'GET_USER_AUTH_TEXTS_SUCCESS';
export const SET_USER_ACTIVE_AUTH_FORM = 'SET_USER_ACTIVE_AUTH_FORM';
export const REG_USER_SUCCESS = 'REG_USER_SUCCESS';
export const LOG_PHONE_USER_SUCCESS = 'LOG_PHONE_USER_SUCCESS';
export const RESEND_SMS_USER_SUCCESS = 'RESEND_SMS_USER_SUCCESS';
export const VERIFY_PHONE_USER_SUCCESS = 'VERIFY_PHONE_SMS_USER_SUCCESS';
export const LOG_EMAIL_USER_SUCCESS = 'LOG_EMAIL_USER_SUCCESS';
export const RESEND_EMAIL_USER_SUCCESS = 'RESEND_EMAIL_USER_SUCCESS';

export const TOGGLE_MODAL_SHOW = 'TOGGLE_MODAL_SHOW';
export const MODAL_SHOW = 'MODAL_SHOW';
export const MODAL_HIDE = 'MODAL_HIDE';
export const SET_MODAL_DATA = 'SET_MODAL_DATA';
export const RESET_MODAL = 'RESET_MODAL';

export const GET_PROMOCODE_SUCCESS = 'GET_PROMOCODE_SUCCESS';
export const APPLY_PROMOCODE_SUCCESS = 'APPLY_PROMOCODE_SUCCESS';
export const REMOVE_PROMOCODE_SUCCESS = 'REMOVE_PROMOCODE_SUCCESS';
export const REMOVE_ALL_PROMOCODES_SUCCESS = 'REMOVE_ALL_PROMOCODES_SUCCESS';
export const APPLY_ALL_PROMOCODES_SUCCESS = 'APPLY_ALL_PROMOCODES_SUCCESS';

export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const TOGGLE_SERVICE = 'TOGGLE_SERVICE';
export const SELECT_SERVICE = 'SELECT_SERVICE';

export const GET_PORTAL_SUCCESS = 'GET_PORTAL_SUCCESS';
export const GET_PORTAL_PRODUCTS_SUCCESS = 'GET_PORTAL_PRODUCTS_SUCCESS';
export const GET_PORTAL_MORE_PRODUCTS_SUCCESS = 'GET_PORTAL_MORE_PRODUCTS_SUCCESS';

export const MARKDOWN = {
    SET: 'SET_MARKDOWN',
}
export const BONUSES = {
    SET: 'SET_BONUSES',
    USER_VERIFICATION_SUCCESS: 'BONUSES_USER_VERIFICATION_SUCCESS',
    USER_VERIFICATION_ERROR: 'BONUSES_USER_VERIFICATION_ERROR',
    PHONE_VERIFY_SUCCESS: 'BONUSES_PHONE_VERIFY_SUCCESS',
    PHONE_VERIFY_ERROR: 'BONUSES_PHONE_VERIFY_ERROR',
    RESEND_SMS_SUCCESS: 'BONUSES_RESEND_SMS_SUCCESS',
    RESEND_SMS_ERROR: 'BONUSES_RESEND_SMS_ERROR',
    TOGGLE_WAITING_REQUEST: 'BONUSES_TOGGLE_WAITING_REQUEST',
    CLEAR_DATA: 'CLEAR_DATA',
    GET_USER_VALUE_SUCCESS: 'BONUSES_GET_USER_VALUE_SUCCESS',
    TOGGLE_BONUSES_LOADER: 'TOGGLE_BONUSES_LOADER',
}

export const CHECKOUT_CONTACTS = {
    UPDATE: 'UPDATE',
    CHANGE_PHONE: 'CHANGE_PHONE',
}

export const PRODUCTS_ADDITIONAL_DATA = {
    SET: 'SET_PRODUCTS_ADDITIONAL_DATA',
    UPDATE: 'UPDATE_PRODUCTS_ADDITIONAL_DATA',
}

export const TOP_PRICE = {
    SET_PRODUCTS_DATA: 'SET_TOP_PRICE_PRODUCT_DATA',
    CLEAR_PRODUCTS_DATA: 'CLEAR_TOP_PRICE_PRODUCT_DATA',
    SET_TOTAL_ORDER_TOP_PRICE: 'SET_TOTAL_ORDER_TOP_PRICE',
    CLEAR_TOTAL_ORDER_TOP_PRICE: 'CLEAR_TOTAL_ORDER_TOP_PRICE',
    GET_DATA_GRAPH_SUCCESS: 'GET_DATA_GRAPH_SUCCESS',
    TOGGLE_INFO: "TOGGLE_INFO",
    SET_TOTAL_ORDER_BONUS: 'SET_TOTAL_ORDER_BONUS',
    CLEAR_TOTAL_ORDER_BONUS: 'CLEAR_TOTAL_ORDER_BONUS',
}

export const GRAPH = {
    CREDIT_GRAPH_SUCCESS: 'CREDIT_GRAPH_SUCCESS',
    CREDIT_GRAPH_GET_ALL: 'CREDIT_GRAPH_GET_ALL',
    CREDIT_GRAPH_GET_CANCEL_PREV: 'CREDIT_GRAPH_GET_CANCEL_PREV',
    CREDIT_PERIOD_GRAPH_REQUEST: 'CREDIT_PERIOD_GRAPH_REQUEST',
}

export const SET_CREDIT_NUMBER_MONTHS = 'SET_CREDIT_NUMBER_MONTHS';

export const FIRST_PAYMENT = {
    SET: 'SET_FIRST_PAYMENT',
}

export const SET_WAIT_GRAPH = 'SET_WAIT_GRAPH';

export const DIIA = {
    GET_DIIA_QR: 'GET_DIIA_QR',
    SET_DIIA_QR: 'SET_DIIA_QR',
    GET_DIIA_USER_INFO: 'GET_DIIA_USER_INFO',
    GET_DIIA_USER_INFO_SUCCESS: 'GET_DIIA_USER_INFO_SUCCESS',
    SET_ERROR: 'SET_ERROR',
    TOGGLE_WAITING_REQUEST: 'TOGGLE_WAITING_REQUEST',
    SET_SHOW_DIIA: 'SET_SHOW_DIIA',
    SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',
    SET_USER_DATA_LOADED: 'SET_USER_DATA_LOADED',
    CLEAR_USER_DATA: 'CLEAR_USER_DATA',
};

export const AIR_DELIVERY = {
    SET_DELIVERY_CHOOSED_AIR_ADDRESS_COURIER_FORM: 'SET_DELIVERY_CHOOSED_AIR_ADDRESS_COURIER_FORM',
}

export const PRICE_INTERNAL = {
    SET_PRICE_INTERNAL_SUCCESS: 'SET_PRICE_INTERNAL_SUCCESS',
}
