const BREAKPOINTS = {
    values: {
        xs: 0,
        sm: 320,
        md: 768,
        lg: 1024,
        xl: 1440,
    },
};

export const $colors = {
    $main: '#00b2f2',
    $mainHover: '#0094ca',
    $secondary: '#ffbf26',
    $secondaryHover: '#f6b61e',
    $third: '#00ac83',
    $thirdHover: '#089976',
    $success: '#00ac45',
    $green: '#5EB300',
    $error: '#fd3321',
    $disable: '#d3d3d4',
    $base: '#f7f8f9',
    $baseSecondary: '#d4d4d4',
    $light: '#e4f2f8',
    $purple: '#494bc1',
    $purpleHover: '#3234a5',
    $violet: '#8e09aa',
    $red: '#FF0202',
    $bonusBackground: '#E5E5E5',
    $quantityBackground: '#F1F1F1',
    $muiLabelColor: '#C8C8C8',
    $white: '#fff',
    $dark: '#222d4a',
    $btnHover: '#008836',
    $lightGreen: '#34A853',
};

export const $textColors = {
    $dark: '#222d4a',
    $secondaryText: '#777e90',
    $darkSecondary: '#292b31',
};

export const $borderColors = {
    $border: '#d5d5d5',
    $borderSecondary: '#d4d4d4',
    $borderGray: '#f4f4f4',
    $borderStandard: '#C2C7D2',
    $borderActive: '#00B2F2',
    $borderHover: '#777E90',
};

export const $labelsColors = {
    $new: '#00ac83',
    $action: $colors.$error,
    $best: '#0097cd',
    $hit: '#ff7816',
    $sold: $colors.$error,
    $exclusive: '#494bc1',
    $advice: '#F34C5C',
    $reviews: '#494bc1',
};

export const $fonts = {
  $baseFont: '\'Wix Madefor Display\', sans-serif',
  $baseFontExtra: '\'Circe Extra\', sans-serif',
  $secondFont: '\'Helvetica\', sans-serif',
  $thirdFont: '\'Roboto\', sans-serif',
  $textBaseSize: '14px',
  $sold: $colors.$error,
  $exclusive: '#494bc1',
  $advice: '#F34C5C',
  $reviews: '#494bc1',
};

const $baseRadius = 4;

export const $others = {
    $baseBorderRadius: `${$baseRadius}px`,
    $commonBoxShadow: '0 4px 4px rgba(34, 45, 74, 0.1)',
    $modalBackdropZindex: '999',
    $borderRadiusXS: `${$baseRadius}px`,
    $borderRadiusSM: `${$baseRadius * 2}px`,
    $borderRadiusXL: `${$baseRadius * 4}px`,
}

export const currentStyle = {
    breakpoints: BREAKPOINTS,
    palette: {
        primary: {
            main: $colors.$main,
        },
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                text: {
                    transform: 'scale(1)',
                },
            },
        },
    },
    typography: {
        fontFamily: $fonts.$baseFont,
        h1: {
            fontSize: 24,
            fontWeight: '800',
            [`@media (min-width: ${BREAKPOINTS.values.md}px)`]: {
                fontSize: 32,
            },
            [`@media (min-width: ${BREAKPOINTS.values.xl}px)`]: {
                fontSize: 48,
            },
        },
    },
};
