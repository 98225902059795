import React from "react";
import styled from "styled-components";
import {$colors, $textColors, $borderColors, $others} from '../../../../../../engine/constants/theme';
import cl from "classnames";

const GreenButton = ({
    type,
    title,
    disabled = false,
    loading = false,
    className = '',
    btnClass = '',
    id = '',
    maxWidth = '320px',
    tooltip = '',
    preventDisableAttr = false,
    ...props
}) => {
    className = ["main-btn", className, (loading ? "loading" : "")].filter(cn => !!cn).join(" ");

    return (
        <StyledButton id={id} className={className} maxWidth={maxWidth}>
            <button type={type}
                    disabled={!preventDisableAttr && disabled}
                    {...props}
                    title={disabled ? tooltip : ''}
                    className={cl(`${btnClass}`, {
                        ...preventDisableAttr && {'btn--disabled': disabled},
                    })}
            >{title}</button>
            <div className="loader"/>
        </StyledButton>
    );
};

const StyledButton = styled.div`${(props) => (`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: ${props.maxWidth};

    @media (max-width: 550px) {
        max-width: none;
    }

    &.blue-btn {
        button {
            box-shadow: 0 2px 10px 0 rgba(1, 159, 255, 0.16), 0 2px 5px 0 rgba(1, 159, 255, 0.26);
            background-color: #00b2f2;

            &:hover {
                background-color: #0094ca;
            }
        }
    }

    &.white-btn {
        button {
            background-color: ${$colors.$white};
            border: 1px solid ${$colors.$success};
            box-shadow: none;
            color: ${$colors.$success}

            &:hover {
                background-color: ${$colors.$white};
                border: 1px solid ${$colors.$btnHover};
                color: ${$colors.$btnHover}
            }
        }

    }

    button {
        width: 100%;
        box-sizing: border-box;
        border: none;
        box-shadow: 0 0 0 0 #7ed321, 0 4px 12px 0 rgba(67,155,0,.35);
        height: 48px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: ${$colors.$success};
        color: ${$colors.$white};
        font-size: 18px;
        border-radius: ${$others.$borderRadiusSM};
        padding: 0 16px;
        cursor: pointer;
        transition: 0.3s ease;
        text-transform: none;
        line-height: 1.2;
        font-weight: 600;

        &:hover {
            background-color: #008a35;
        }

        @media (max-width: 767px) {
            font-weight: 700;
        }

        &[disabled] {
            opacity: .4;
            color: #ffffff;
            background: ${$colors.$disable};
            box-shadow: none;
            cursor: default;

            &:hover {
               color: #ffffff;
                background: ${$colors.$disable};
                box-shadow: none;
            }
        }
    }

    &:hover {
        .loader {
            &:after {
                background-color: #77e201;
            }
        }
    }

    &.loading {
        button {
            text-indent: -9999em;
            cursor: default;
        }

        .loader {
            display: block;
            left: calc(50% - 16px);
            top: calc(50% - 16px);
        }
    }

    .loader {
        display: none;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #ffffff;
        background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
        animation: loader 1.4s infinite linear;
        transform: translateZ(0);
        position: absolute;

        &:before {
            width: 50%;
            height: 50%;
            background-color: #ffffff;
            border-radius: 100% 0 0 0;
            position: absolute;
            top: 0;
            left: 0;
            content: "";
        }

        &:after {
            background-color: #65c000;
            width: 75%;
            height: 75%;
            border-radius: 50%;
            content: "";
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            transition: all .2s linear;
        }
    }

    @keyframes loader {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    button.rds-btn {
    }

    button.btn--yellow {
        color: ${$textColors.$dark};
        background: ${$colors.$secondary};
        box-shadow: none;

        &:hover {
            background: ${$colors.$secondaryHover};
        }
    }

    button.btn--cancel {
        color: ${$colors.$base};
        background: ${$borderColors.$borderSecondary};
        box-shadow: none;

        &:hover {
            background: ${$borderColors.$borderSecondary};
        }
    }

    button.btn--black {
        color: #ffffff;
        background: #000000;
        box-shadow: none;

        &:hover {
           color: #ffffff;
            background: #000000;
            box-shadow: none;
        }
    }

    button.btn--blue {
        color: #ffffff;
        background: ${$colors.$mainHover};
        box-shadow: none;

        &:hover {
           color: #ffffff;
            background: ${$colors.$mainHover};
            box-shadow: none;
        }
    }

    button.btn--disabled {
        color: #ffffff;
        background: ${$colors.$disable};
        box-shadow: none;
        cursor: default;

        &:hover {
           color: #ffffff;
            background: ${$colors.$disable};
            box-shadow: none;
        }
    }

`)}`;

export default GreenButton;
