import * as actionTypes from "../actions/actionTypes";
import { APPLY_ALL_PROMOCODES_SUCCESS } from '../actions/actionTypes';

const initialState = null;

const promocodesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PROMOCODE_SUCCESS:
            return action.payload;

        case actionTypes.APPLY_PROMOCODE_SUCCESS:
        case actionTypes.REMOVE_PROMOCODE_SUCCESS:
        case actionTypes.REMOVE_ALL_PROMOCODES_SUCCESS:
            return {
                ...state,
                ...{
                    list: action.payload.list,
                },
            };
        case actionTypes.APPLY_ALL_PROMOCODES_SUCCESS:
            return {
                ...state,
                ...{
                    isAutoPromocodes: action.payload,
                },
            };

        default:
            return state;
    }
};

export default promocodesReducer;
