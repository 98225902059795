import {
    UPLOAD_DOCUMENT_SUCCESS,
    REMOVE_DOCUMENT_SUCCESS,
    CHECKOUT_CONTACTS, UPDATE_COMPANIES_DATA,
} from '../actions/actionTypes';

const initialState = null;

const contactsReducer = (state = initialState, action) => {
    const payload = action.payload;

    switch (action.type) {
        case CHECKOUT_CONTACTS.UPDATE:
            return {...state, ...payload};
        case UPLOAD_DOCUMENT_SUCCESS:
            return {...state, ...{[payload.type]: payload}}
        case REMOVE_DOCUMENT_SUCCESS:
            return {...state, ...{[payload.type]: null}}
        case UPDATE_COMPANIES_DATA:
            return {...state, ...payload}
        default:
            return state;
    }
}

export default contactsReducer;
